import { Link } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import BodyClassName from 'react-body-classname';
import bgNotFound from 'assets/images/common/bg-not-found.jpeg';

export const Head = () => (
  <>
    <title>404 - Nie znaleziono strony</title>
    <link rel="canonical" href="https://fairfur.pl/404/" />
  </>
);

const Main = styled.main`
  background: #fae9cb url(${bgNotFound}) 50% 0;
  margin-bottom: 0;
  min-height: 765px;
`;

const NotFound = styled.section`
  max-width: 300px;
  margin: 0 auto;
  padding: 75px 10px;
  text-align: center;

  h1 {
    font-size: 7.1rem;
    margin: 0 0 2rem;
  }

  p {
    font-size: 3rem;
    line-height: 110%;
  }

  .btn {
    max-width: 225px;
  }
`;

const NotFoundPage = () => (
  <BodyClassName className="we-last-one">
    <Main>
      <div className="row">
        <NotFound>
          <h1>404</h1>
          <p>Strona nie została znaleziona</p>
          <Link to="/" className="btn">Przejdź do strony głównej</Link>
        </NotFound>
      </div>
    </Main>
  </BodyClassName>
);

export default NotFoundPage;
